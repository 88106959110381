<template>
    <div>
        <Header></Header>
        <div class="outer_box content_box">
            <el-page-header class="pg_header" 
                            @back="()=>{$router.push('/')}" 
                            :content="$t('Messages')">
            </el-page-header>
            <el-empty v-if="msgList.length === 0" :description="$t('No record found')"></el-empty>
            <div>
                <div class="msg_list" v-for="(item,index) in msgList" :key="index">
                    <p class="msg_list_title">{{item.title}}</p>
                    <p class="msg_list_desc">{{item.desc}}</p>
                    <span class="time">{{item.time}}</span>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import { getTableRows } from '@/utils/RequestsRpc'
export default {
  components:{ Header, Footer },
  data(){
      return{
          msgList:[]
      }
  },
  methods:{
      timestampToTime(timestamp) {
            if (timestamp.length < 10) { return ' ' }
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate() + ' ';
            var h = date.getHours() + ':';
            var m = date.getMinutes() + ':';
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
      },
      getList(){
            let local = JSON.parse(window.localStorage.getItem('pcLocalAccount'));
            let language = window.localStorage.getItem("language")
            getTableRows({
                code: 'testex2',
                scope: local.nft_account,
                table: 'deallogs',
            }).then((res) => {
                let newArr = [];
                for (let i in res.rows) {
                    let item = res.rows[i];
                    let title = '';
                    let desc = '';
                    if (item.dealtype === 'buy') {
                        title = this.$t('Copyright Purchase Tips')
                        if(language === "cn"){
                            desc = `您购买的作品《${item.tokname}》,版权已转至您的账户，成交金额为：${item.price}`;
                        }else{
                            desc = `The copyright of "${item.tokname}" that you purchase priced ${item.price}, has been transferred to your account.`
                        }
                    } else if (item.dealtype === 'sell') {
                        title = this.$t('Copyright Sale Tips')
                        if(language === "cn"){
                            desc = `您的作品《${item.tokname}》,版权已成功售出，成交金额为：${item.price}，金额已转移至你的账户`;
                        }else{
                            desc = `The copyright of your work "${item.tokname}" has been sold at price ${item.price}, funds have been transferred to your account.`
                        }
                    } else if (item.dealtype === 'pay') {
                        title = this.$t('Order Successfully Placed')
                        if(language === "cn"){
                            desc = `您已成功订购观看《${item.tokname}》,成交金额为：${item.value}，已开通观看权限`;
                        }else{
                            desc = `You have successfully ordered to watch "${item.tokname}" at $${item.value}, you now have the permission to watch`
                        }
                    } else if (item.dealtype === 'accredit') {
                        title = this.$t('Order Notification')
                        if(language === "cn"){
                            desc = `您的作品《${item.tokname}》已成功被订购观看,成交金额为：${item.value}，金额已转移至你的账户`;
                        }else{
                            desc = `Someone has ordered your work "${item.tokname}" for watching at price ${item.value}, funds have been transferred to your account`
                        }
                    }
                    let info = { title: title, desc: desc, time: this.timestampToTime(item.dealtime)};
                    newArr.push(info);
                }
                this.msgList = newArr;
            })
        }
  },
  created(){
      this.getList()
  }
}
</script>
<style scoped lang="less">
@import url("./index.less");
</style>